import type { Partners } from "~/types"

const partners: Partners = {
  lawFirms: [
    {
      img: "/partners/logo_uriax300.png",
      company: "Uriá Menéndez",
      employees: [
        {
          name: "Enrique Nieto Bracklemanns",
          email: "enrique.nieto@uria.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Debt & Equity Capital Markets",
            es: "Mercados de Capitales de Deuda y Renta Variable"
          }],
          "profile": {
            en: "https://www.uria.com/en/abogados/enb-enrique-nieto-brackelmanns",
            es: "https://www.uria.com/es/abogados/enb-enrique-nieto-brackelmanns"

          }
        }
      ]
    },
    {
      img: "/partners/logo_hoganx300.png",
      company: "Hogan Lovells",
      employees: [
        {
          name: "Emilio Gómez Delgado",
          email: "emilio.gomez@hoganlovells.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Real Estate & REITs",
            es: "Inmobiliario y SOCIMIs"
          }],
          "profile": {
            en: "https://www.hoganlovells.com/en/emilio-gomez",
            es: "https://www.hoganlovells.com/es/emilio-gomez"

          }
        }
      ]
    },
    {
      img: "/partners/logo_garriguesx300.png",
      company: "Garrigues",
      employees: [
        {
          name: "Gonzalo García-Fuertes Iglesias",
          email: "gonzalo.garcia-fuertes@garrigues.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Debt & Equity Capital Markets",
            es: "Mercados de Capitales de Deuda y Renta Variable"
          }],
          "profile": {
            en: "https://www.garrigues.com/en_GB/team/gonzalo-garcia-fuertes-iglesias",
            es: "https://www.garrigues.com/es_ES/equipo/gonzalo-garcia-fuertes-iglesias"
          }
        },
        {
          name: "José Luis Palao Iturzaeta",
          email: "jose.luis.palao.iturzaeta@garrigues.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Debt & Equity Capital Markets",
            es: "Mercados de Capitales de Deuda y Renta Variable"
          }],
          "profile": {
            en: "https://www.garrigues.com/en_GB/team/jose-luis-palao-iturzaeta",
            es: "https://www.garrigues.com/es_ES/equipo/jose-luis-palao-iturzaeta"

          }
        }
      ]
    },
    {
      img: "/partners/logo_cliffordx300.png",
      company: "Cliﬀord Chance",
      employees: [
        {
          name: "Pablo Serrano de Haro",
          email: "pablo.serrano@cliffordchance.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "REITs and Tax",
            es: "SOCIMIs y Fiscal"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/pablo-serrano-de-haro-mart%C3%ADnez-8423a4a6/",
            es: "https://www.linkedin.com/in/pablo-serrano-de-haro-mart%C3%ADnez-8423a4a6/"

          }
        }
      ]
    },
    {
      img: "/partners/logo_ashurstx300.png",
      company: "Ashurst",
      employees: [
        {
          name: "Tara Waters",
          email: "tara.waters@ashurst.com",
          city: {
            en: "London",
            es: "Londres"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Debt Capital Markets (EU & USA)",
            es: "Mercados de Capitales de Deuda (UE y USA)"
          }],
          "profile": {
            en: "https://www.ashurst.com/en/people/tara-waters/",
            es: "https://www.ashurst.com/en/people/tara-waters/"
          }
        },
        {
          name: "Pedro Ester Palacios",
          email: "pedro.ester@ashurst.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "M&A & Private Equity",
            es: "M&A y Private Equity"
          }],
          "profile": {
            en: "https://www.ashurst.com/en/people/pedro-ester-palacios/",
            es: "https://www.ashurst.com/en/people/pedro-ester-palacios/"

          }
        }
      ]
    },
    {
      img: "/partners/logo_kpmg.png",
      company: "KPMG",
      employees: [
        {
          name: "Maitane de la Peña",
          email: "mdelapena1@kpmg.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Director",
            es: "Directora"
          },
          department: [{
            en: "Capital Markets",
            es: "Mercados de Capitales"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/maitanedelapena/",
            es: "https://www.linkedin.com/in/maitanedelapena/"

          }
        }
      ]
    },
    {
      img: "/partners/logo_perezllorcax300.png",
      company: "Pérez-Llorca",
      employees: [
        {
          name: "Josefina García Pedroviejo",
          email: "jgarciapedroviejo@perezllorca.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socia"
          },
          department: [{
            en: "Financial Services & Investment Funds",
            es: "Servicios Financieros y Fondos de Inversión"
          }],
          "profile": {
            en: "https://www.perezllorca.com/en/lawyer/josefina-garcia-pedroviejo/",
            es: "https://www.perezllorca.com/abogado/josefina-garcia-pedroviejo/"
          }
        },
        {
          name: "Yolanda Azanza Crespo",
          email: "yazanza@perezllorca.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socia"
          },
          department: [{
            en: "Debt & Equity Capital Markets",
            es: "Mercados de Capitales de Deuda y Renta Variable"
          }],
          "profile": {
            en: "https://www.perezllorca.com/en/lawyer/yolanda-azanza/",
            es: "https://www.perezllorca.com/abogado/yolanda-azanza/"

          }
        }
      ]
    },
    {
      img: "/partners/logo_allenoveryx300-1.png",
      company: "Allen & Overy",
      employees: [
        {
          name: "Salvador Ruiz Bachs",
          email: "salvador.ruizbachs@allenovery.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Debt Capital Markets",
            es: "Mercados de Capitales de Deuda"
          }],
          "profile": {
            en: "https://www.aoshearman.com/en/people/salvador-norberto-ruiz-bachs",
            es: "https://www.aoshearman.com/en/people/salvador-norberto-ruiz-bachs"

          }
        }
      ]
    },
    {
      img: "/partners/logo_fresh2x300.png",
      company: "Freshfields Bruckhaus Deringer",
      employees: [
        {
          name: "Armando Albarrán",
          email: "armando.albarran@freshfields.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Corporate and M&A",
            es: "Corporate y M&A"
          }],
          "profile": {
            en: "https://www.freshfields.com/en-gb/contacts/find-a-lawyer/a/albarran-jose-armando/",
            es: "https://www.freshfields.com/en-gb/contacts/find-a-lawyer/a/albarran-jose-armando/"
          }
        },
        {
          name: "Alfonso de Marcos",
          email: "alfonso.demarcos@freshfields.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Corporate and M&A",
            es: "Corporate y M&A"
          }],
          "profile": {
            en: "https://www.freshfields.com/en-gb/contacts/find-a-lawyer/d/de-marcos-alfonso/",
            es: "https://www.freshfields.com/en-gb/contacts/find-a-lawyer/d/de-marcos-alfonso/"

          }
        }
      ]
    },
    {
      img: "/partners/logo_dlax300.png",
      company: "DLA Piper",
      employees: [
        {
          name: "Jesús Palencia",
          email: "Jesus.Palencia@dlapiper.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Of Counsel",
            es: "Of Counsel"
          },
          department: [{
            en: "Capital Markets",
            es: "Mercados de capitales"
          }],
          "profile": {
            en: "https://www.dlapiper.com/es-es/people/p/palencia-jesus",
            es: "https://www.dlapiper.com/es-es/people/p/palencia-jesus"

          }
        }
      ]
    },
    {
      img: "/partners/logo_pwcx300.png",
      company: "PWC",
      employees: [
        {
          name: "Beltrán Gómez de Zayas",
          email: "beltran.gomezdezayas@pwc.com",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Banking & Finance",
            es: "Área Bancario y Financiero"
          }],
          "profile": {
            en: "https://www.pwc.es/es/contacts/b/beltran-gomez-zayas.html",
            es: "https://www.pwc.es/es/contacts/b/beltran-gomez-zayas.html"
          }
        },
        {
          name: "Javier Mateos",
          email: "javier.mateos.sanchez@pwc.com",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Corporate, M&A & Capital Markets",
            es: "Corporate, M&A y Mercados de capitales"
          }],
          "profile": {
            en: "https://www.pwc.es/es/contacts/j/javier-mateos.html",
            es: "https://www.pwc.es/es/contacts/j/javier-mateos.html"

          }
        }
      ]
    },
    {
      img: "/partners/logo_ryc_300x.png",
      company: "Ramón y Cajal",
      employees: [
        {
          name: "Carlos Lao",
          email: "clao@ramoncajal.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Capital Markets",
            es: "Mercados de Capitales"
          }],
          "profile": {
            en: "https://www.ramonycajalabogados.com/en/profesional/carlos-lao",
            es: "https://www.ramonycajalabogados.com/es/profesional/carlos-lao"
          }
        },
        {
          name: "Miguel Ferre",
          email: "mferre@ramoncajal.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Real Estate",
            es: "Inmobiliario"
          }],
          "profile": {
            en: "https://www.ramonycajalabogados.com/en/profesional/miguel-ferre",
            es: "https://www.ramonycajalabogados.com/es/profesional/miguel-ferre"
          }
        },
        {
          name: "Alfonso Cárcamo",
          email: "acarcamo@ramoncajal.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Corporate, M&A & Capital Markets",
            es: "Corporate, M&A y Mercados de Capitales"
          }],
          "profile": {
            en: "https://www.ramonycajalabogados.com/en/profesional/alfonso-carcamo",
            es: "https://www.ramonycajalabogados.com/es/profesional/alfonso-carcamo"

          }
        }
      ]
    },
    {
      img: "/partners/logo_linklatersx300.png",
      company: "Linklaters",
      employees: [
        {
          name: "Jorge Alegre",
          email: "jorge.alegre@linklaters.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Capital Markets",
            es: "Mercados de Capitales"
          }],
          "profile": {
            en: "https://www.linklaters.com/en/find-a-lawyer/jorge-alegre",
            es: "https://www.linklaters.com/en/find-a-lawyer/jorge-alegre"
          }
        },
        {
          name: "Pablo Medina",
          email: "pablo.medina@linklaters.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Capital Markets",
            es: "Mercados de Capitales"
          }],
          "profile": {
            en: "https://www.linklaters.com/en/find-a-lawyer/pablo-medina",
            es: "https://www.linklaters.com/en/find-a-lawyer/pablo-medina"

          }
        }
      ]
    },
    {
      img: "/partners/logo_bdox300.png",
      company: "BDO",
      employees: [
        {
          name: "Juan Gómez-Acebo Sáenz de Heredia",
          email: "juan.gomez-acebo@bdo.es",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Of Counsel",
            es: "Of Counsel"
          },
          department: [{
            en: "Corporate Real Estate",
            es: "Inmobiliario"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/juan-gomez-acebo/",
            es: "https://www.linkedin.com/in/juan-gomez-acebo/"
          }
        },
        {
          name: "Ángel Vizcaíno Perea",
          email: "angel.vizcaino@bdo.es",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Corporate Real Estate",
            es: "Inmobiliario"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/%C3%A1ngel-vizca%C3%ADno-perea-7bbbb23b/",
            es: "https://www.linkedin.com/in/%C3%A1ngel-vizca%C3%ADno-perea-7bbbb23b/"

          }
        }
      ]
    },
    {
      img: "/partners/logo_andersenx300c.png",
      company: "Andersen",
      employees: [
        {
          name: "Marta Morales Crespo",
          email: "marta.morales@es.andersen.com",
          city: {
            en: "Sevilla",
            es: "Sevilla"
          },
          rol: {
            en: "Partner",
            es: "Socia"
          },
          department: [{
            en: "Equity Capital Markets",
            es: "Mercados de Capitales de Acciones"
          }],
          "profile": {
            en: "https://es.andersen.com/en/professionals/marta-morales-crespo.html",
            es: "https://es.andersen.com/es/profesionales/marta-morales-crespo.html"
          }
        },
        {
          name: "Jorge Cuello Blanco-Morales",
          email: "jorge.cuello@es.andersen.com",
          city: {
            en: "Málaga",
            es: "Málaga"
          },
          rol: {
            en: "Director",
            es: "Director"
          },
          department: [{
            en: "Debt Capital Markets",
            es: "Mercados de Capitales de Deuda"
          }],
          "profile": {
            en: "https://es.andersen.com/en/professionals/jorge-cuello.html",
            es: "https://es.andersen.com/es/profesionales/jorge-cuello.html"
          }
        },
        {
          name: "Guillermo Muñoz-Alonso",
          email: "guillermo.munoz-alonso@es.andersen.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Banking & Finance",
            es: "Bancario y Financiero"
          }],
          "profile": {
            en: "https://es.andersen.com/en/professionals/guillermo-munoz-alonso.html",
            es: "https://es.andersen.com/es/profesionales/guillermo-munoz-alonso.html"
          }
        }
      ]
    },
    {
      img: "/partners/logo_martinezetxex300.png",
      company: "Martínez-Echevarría",
      employees: [
        {
          name: "Iván Picó Madrigal",
          email: "ivan.pico@martinezechevarria.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Real Estate & REITs",
            es: "Inmobiliario y SOCIMIs"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/ivan-pico/",
            es: "https://www.linkedin.com/in/ivan-pico/"
          }
        }
      ]
    },
    {
      img: "/partners/logo_gomezacebox300.png",
      company: "Gómez-Acebo & Pombo",
      employees: [
        {
          name: "Guillermo Guerra",
          email: "gguerra@ga-p.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Capital Markets",
            es: "Mercados de Capitales"
          }],
          "profile": {
            en: "https://www.ga-p.com/en/lawyers/guillermo-guerra-martin/",
            es: "https://www.ga-p.com/abogados/guillermo-guerra-martin/"
          }
        },
        {
          name: "Augusto Piñel",
          email: "apinel@ga-p.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Capital Markets",
            es: "Mercados de Capitales"
          }],
          "profile": {
            en: "https://www.ga-p.com/en/abogados/augusto-pinel/",
            es: "https://www.ga-p.com/abogados/augusto-pinel/"

          }
        }
      ]
    },
    {
      img: "/partners/logo_birdx300.png",
      company: "Bird & Bird",
      employees: [
        {
          name: "Conchita Sainz Sodupe",
          email: "conchita.sainz@twobirds.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socia"
          },
          department: [{
            en: "Real Estate",
            es: "Inmobiliario"
          }],
          "profile": {
            en: "https://www.twobirds.com/en/people/c/conchita-sainz",
            es: "https://www.twobirds.com/es/people/c/conchita-sainz"
          }
        },
        {
          name: "Fernando Gutiérrez Rizaldos",
          email: "fernando.gutierrez@twobirds.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Financial & Regulatory Financial",
            es: "Financiero & Regulatorio Financiero"
          }],
          "profile": {
            en: "https://www.twobirds.com/en/people/f/fernando-gutierrez",
            es: "https://www.twobirds.com/es/people/f/fernando-gutierrez"

          }
        }
      ]
    },
    {
      img: "/partners/logo_cuatrecasasx300.png",
      company: "Cuatrecasas",
      employees: [
        {
          name: "José Ramón Berecibar",
          email: "joseramon.berecibar@cuatrecasas.com",
          city: {
            en: "Bilbao",
            es: "Bilbao"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "",
            es: ""
          }],
          "profile": {
            en: "https://www.cuatrecasas.com/en/spain/lawyers/jose-ramon-berecibar",
            es: "https://www.cuatrecasas.com/es/spain/abogados/jose-ramon-berecibar"
          }
        },
        {
          name: "Juan Aguayo",
          email: "juan.aguayo@cuatrecasas.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "",
            es: ""
          }],
          "profile": {
            en: "https://www.cuatrecasas.com/en/spain/lawyers/juan-aguayo",
            es: "https://www.cuatrecasas.com/es/spain/abogados/juan-aguayo"
          }
        },
        {
          name: "Jose Luis Rodriguez",
          email: "joseluis.rodriguez@cuatrecasas.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "",
            es: ""
          }],
          "profile": {
            en: "https://www.cuatrecasas.com/en/spain/lawyers/jose-luis-rodriguez",
            es: "https://www.cuatrecasas.com/es/spain/abogados/jose-luis-rodriguez"
          }
        },
        {
          name: "Ignacio Escrivá",
          email: "ignacio.escrivaderomani@cuatrecasas.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "",
            es: ""
          }],
          "profile": {
            en: "https://www.cuatrecasas.com/en/spain/lawyers/ignacio-escriva-de-romani",
            es: "https://www.cuatrecasas.com/es/spain/abogados/ignacio-escriva-de-romani"
          }
        },
        {
          name: "Pere Kirchner",
          email: "p.kirchner@cuatrecasas.com",
          city: {
            en: "Barcelona",
            es: "Barcelona"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "",
            es: ""
          }],
          "profile": {
            en: "https://www.cuatrecasas.com/en/spain/lawyers/pere-kirchner",
            es: "https://www.cuatrecasas.com/es/spain/abogados/pere-kirchner"
          }
        },
        {

          name: "Gerard Correig",
          email: "gerard.correig@cuatrecasas.com",
          city: {
            en: "Barcelona",
            es: "Barcelona"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "",
            es: ""
          }],
          "profile": {
            en: "https://www.cuatrecasas.com/en/spain/lawyers/gerard-correig",
            es: "https://www.cuatrecasas.com/es/spain/abogados/gerard-correig"
          }
        },
        {
          name: "Roger Freixes",
          email: "roger.freixes@cuatrecasas.com",
          city: {
            en: "Barcelona",
            es: "Barcelona"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "",
            es: ""
          }],
          "profile": {
            en: "https://www.cuatrecasas.com/en/spain/lawyers/roger-freixes",
            es: "https://www.cuatrecasas.com/es/spain/abogados/roger-freixes"
          }
        },
        {
          name: "Ana Mª Gamazo",
          email: "anamaria.gamazo@cuatrecasas.com",
          city: {
            en: "Barcelona",
            es: "Barcelona"
          },
          rol: {
            en: "Partner",
            es: "Socia"
          },
          department: [{
            en: "Financial and Capital Markets",
            es: "Financiero y Mercado de Capitales"
          }],
          "profile": {
            en: "https://www.cuatrecasas.com/en/spain/lawyers/ana-ma-gamazo",
            es: "https://www.cuatrecasas.com/es/spain/abogados/ana-ma-gamazo"
          }
        }
      ]
    },
    {
      img: "/partners/logo_ecijax300.png",
      company: "Ecija",
      employees: [
        {
          name: "Miguel Sánchez",
          email: "msanchezr@ecija.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Capital Markets",
            es: "Mercados de Capitales"
          }],
          "profile": {
            en: "https://ecija.com/en/abogados/miguel-sanchez/",
            es: "https://ecija.com/abogados/miguel-sanchez-2/"
          }
        },
        {
          name: "Alberto Alonso Ureba",
          email: "aalonso@ecija.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Corporate & Governance",
            es: "Societario y gobernanza"
          }],
          "profile": {
            en: "https://ecija.com/en/abogados/alonso-ureba/",
            es: "https://ecija.com/abogados/alonso-ureba/"
          }
        },
        {
          name: "Alonso Hurtado",
          email: "alhurtado@ecija.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Tech, Media & Telco",
            es: "Tecnología, Medios y Telecomunicaciones"
          },
          {
            en: "Privacy & Compliance",
            es: "Privacidad y Cumplimiento Normativo"
          }],
          "profile": {
            en: "https://ecija.com/en/abogados/alonso-hurtado/",
            es: "https://ecija.com/abogados/alonso-hurtado/"

          }
        }
      ]
    },
    {
      img: "/partners/logo_dentonsx300.png",
      company: "Dentons",
      employees: [
        {
          name: "Ignacio Corujo del Valle",
          email: "ignacio.corujo@dentons.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Capital Markets",
            es: "Mercados de Capitales"
          }],
          "profile": {
            en: "https://www.dentons.com/en/ignacio-corujo",
            es: "https://www.dentons.com/es/ignacio-corujo"

          }
        }
      ]
    },
    {
      img: "/partners/logo_crowex300.png",
      company: "Crowe",
      employees: [
        {
          name: "Joaquín Alegre Zalve",
          email: "joaquin.alegre@crowe.es",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Banking & Finance",
            es: "Área Bancario y Financiero"
          }],
          "profile": {
            en: "https://www.crowe.com/es/about-us/our-people/legal-y-tributario/crowe-legal-y-tributario/joaquin-alegre",
            es: "https://www.crowe.com/es/about-us/our-people/legal-y-tributario/crowe-legal-y-tributario/joaquin-alegre"
          }
        }
      ]
    },
    {
      img: "/partners/logo_swx300.png",
      company: "SW",
      employees: [
        {
          name: "Loreta Calero Pérez",
          email: "lcalero@sw-spain.com",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Partner",
            es: "Socia"
          },
          department: [{
            en: "Advisory",
            es: "Advisory"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/loretacalero/",
            es: "https://www.linkedin.com/in/loretacalero/"
          }
        },
        {
          name: "Álvaro Pericas Ruiz",
          email: "apericas@sw-spain.com",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Tax & Legal",
            es: "Legal y Fiscal"
          }]
        }
      ]
    },
    {
      img: "/partners/logo_evergreenx300.png",
      company: "Evergreen Legal",
      employees: [
        {
          name: "Alberto Campo Caballero",
          email: "a.campo@evergreenlegal.es",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Banking & Finance",
            es: "Área Bancario y Financiero"
          }],
          "profile": {
            en: "https://www.evergreenlegal.es/equipo/alberto-campo/",
            es: "https://www.evergreenlegal.es/equipo/alberto-campo/"
          }
        }
      ]
    },
    {
      img: "/partners/logo_evershedsx300.png",
      company: "Eversheds Sutherland",
      employees: [
        {
          name: "Manuel López",
          email: "mlopez@eversheds-sutherland.es",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Banking & Finance",
            es: "Área Bancario y Financiero"
          }],
          "profile": {
            en: "https://www.eversheds-sutherland.com/en/spain/people/lopez-manuel",
            es: "https://www.eversheds-sutherland.com/es/spain/people/lopez-manuel"
          }
        }
      ]
    },
    {
      img: "/partners/logo_barrilerox300.png",
      company: "Barrilero",
      employees: [
        {
          name: "Tomás Barrilero",
          email: "t.barrilero.@barrilero.es",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Legal Angels",
            es: "Legal Angels"
          }],
          "profile": {
            en: "https://www.barrilero.com/team/tomas-barrilero/?lang=en",
            es: "https://www.barrilero.com/equipo/tomas-barrilero/"
          }
        },
        {
          name: "Alfonso Martínez Parras",
          email: "a.martinez@barrilero.es",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Private Wealth & Regulatory Financial",
            es: "Private Wealth y Regulatorio Financiero"
          }],
          "profile": {
            en: "https://www.barrilero.com/team/alfonso-martinez/?lang=en",
            es: "https://www.barrilero.com/equipo/alfonso-martinez/"
          }
        },
        {
          name: "Ignacio López-Balcells",
          email: "ilopezbalcells@barrilero.es",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Corporate, M&A & Capital Markets",
            es: "Corporate, M&A y Mercados de capitales"
          }],
          "profile": {
            en: "https://www.barrilero.com/team/ignacio-lopez-balcells/?lang=en",
            es: "https://www.barrilero.com/equipo/ignacio-lopez-balcells/"
          }
        }
      ]
    },
    {
      img: "/partners/logo_deloittex300.png",
      company: "Deloitte Legal",
      employees: [
        {
          name: "Francisco Javier Ramírez Arbués",
          email: "framirezarbues@deloitte.es",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socio"
          },
          department: [{
            en: "Regulatory & Compliance",
            es: "Regulatorio y Cumplimiento Normativo"
          }],
          "profile": {
            en: "https://www.deloitte.com/es/es/about/people/profiles.fjramirezarbues%2Bf2d80dc9.html",
            es: "https://www.deloitte.com/es/es/about/people/profiles.fjramirezarbues%2Bf2d80dc9.html"
          }
        }
      ]
    },
    {
      img: "/partners/logo_eyx300.png",
      company: "EY",
      employees: [
        {
          name: "Mónica Represa Rebaque",
          email: "monica.represa@es.ey.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Partner",
            es: "Socia"
          },
          department: [{
            en: "Corporate Governance & Capital Markets",
            es: "Gobierno Corporativo y Mercados de Capitales"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/mónica-represa-2b703811/",
            es: "https://www.linkedin.com/in/mónica-represa-2b703811/"
          }
        }
      ]
    }
  ],
  "mtfAdvisors": [
    {
      img: "/partners/logo_armanextx300.png",
      company: "Armanext",
      employees: [
        {
          name: "Antonio Fernández Hernando",
          email: "antonio.fernandez@armanext.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "President",
            es: "Presidente"
          },
          department: [{
            en: "PYME & SOCIMI Listing advisors",
            es: "Asesoramiento en Salidas a Bolsa de PYMES y SOCIMIs"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/antoniofernandezarmanext/",
            es: "https://www.linkedin.com/in/antoniofernandezarmanext/"

          }
        }
      ]
    },
    {
      img: "/partners/logo_abbacox300.png",
      company: "Abbaco Markets",
      employees: [
        {
          name: "Ana Hernández",
          email: "ana.hernandez@abbacomarkets.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Co-founder",
            es: "Co-founder"
          },
          department: [{
            en: "Capital Markets",
            es: "Mercados de Capitales"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/ana-hern%C3%A1ndez-phd-cfa-90b06617/",
            es: "https://www.linkedin.com/in/ana-hern%C3%A1ndez-phd-cfa-90b06617/"
          }
        },
        {
          name: "Virginia García-Trevijano Hinojosa",
          email: "vgtrevijano@abbacomarkets.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Co-founder",
            es: "Co-founder"
          },
          department: [{
            en: "Capital Markets",
            es: "Mercados de Capitales"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/virginia-garc%C3%ADa-trevijano-hinojosa-3b091276/",
            es: "https://www.linkedin.com/in/virginia-garc%C3%ADa-trevijano-hinojosa-3b091276/"
          }
        }
      ]
    },
    {
      img: "/partners/logo_vgmx300.png",
      company: "VGM",
      employees: [
        {
          name: "Gerardo Torres de las Heras",
          email: "gtorres@vgmadvisory.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Managing Partner",
            es: "Socio Director"
          },
          department: [{
            en: "Debt & Equity Capital",
            es: "Mercados de Capitales de Deuda y Renta Variable"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/gerardo-torres-de-las-heras-9a8b3426/",
            es: "https://www.linkedin.com/in/gerardo-torres-de-las-heras-9a8b3426/"
          }
        }
      ]
    }
  ],
  "investmentFirms": [
    {
      img: "/partners/logo_gpm_x300.png",
      company: "GPM",
      employees: [
        {
          name: "Carlos Ladero",
          email: "cladero@gpmbroker.com",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Sales Manager",
            es: "Director Comercial"
          },
          department: [{
            en: "Debt & Equity Capital Markets, Market Member",
            es: "Mercados de Capitales de Deuda y Renta Variable, Miembro de Mercado"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/carlosladero/",
            es: "https://www.linkedin.com/in/carlosladero/"

          }
        }
      ]
    }
  ],
  "listingAndCorporateServices": [
    {
      img: "/partners/logo_apexx300.png",
      company: "Apex",
      employees: [
        {
          name: "Paloma González",
          email: "paloma.gonzalez@apexfs.group",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Director",
            es: "Directora"
          },
          department: [{
            en: "Regional Head of Operations Corporate Solutions South/East Europe",
            es: "Directora Regional de Operaciones Corporate Solutions Sur/Este de Europa"
          }],
          "profile": {
            en: "https://www.sannegroup.com/our-people/paloma-gonzalez/",
            es: "https://www.sannegroup.com/our-people/paloma-gonzalez/"
          }
        }
      ]
    },
    {
      img: "/partners/logo_citcox300.png",
      company: "Citco",
      employees: [
        {
          name: "Conchita Castaño",
          email: "cocastano@citco.com",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Managing Director",
            es: "Directora General"
          },
          department: [{
            en: "Corporate Solutions",
            es: "Soluciones Corporativas"
          }],
          "profile": {
            en: "https://www.citco.com/",
            es: "https://www.citco.com/"
          }
        }
      ]
    },
    {
      img: "/partners/logo_palmerx300.png",
      company: "Palmer",
      employees: [
        {
          name: "Fátima Fernández-Tagle",
          email: "fatima.tagle@palmerfs.com",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Head Business Development and Deal Closing",
            es: "Directora Desarrollo de Negocio"
          },
          department: [{
            en: "",
            es: ""
          }],
          "profile": {
            en: "http://palmerfs.com/people/fatima-fernandez-tagle/",
            es: "http://palmerfs.com/people/fatima-fernandez-tagle/"
          }
        },
        {
          name: "Manuel Rodríguez de Andrés",
          email: "manuel.rodriguez@palmerfs.com",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Managing Director",
            es: "Director General"
          },
          department: [{
            en: "",
            es: ""
          }],
          "profile": {
            en: "https://www.palmerfs.com/people/manuel-rodriguez/",
            es: "https://www.palmerfs.com/people/manuel-rodriguez/"
          }
        }
      ]
    }
  ],
  "companyAndAssetsValuations": [
    {
      img: "/partners/logo_gesvaltx300.png",
      company: "Gesvalt",
      employees: [
        {
          name: "Clara Tejerizo Cabello",
          email: "ctejerizo@gesvalt.es",
          city: {
            en: "Madrid",
            es: "Madrid"
          },
          rol: {
            en: "Business Development Manager",
            es: "Directora de Desarrollo de Negocio"
          },
          department: [{
            en: "Company & Asset valuation",
            es: "Valoración de Empresas y Activos"
          }],
          "profile": {
            en: "https://www.linkedin.com/in/clara-tejerizo-0b3b3530/",
            es: "https://www.linkedin.com/in/clara-tejerizo-0b3b3530/"
          }
        }
      ]
    },
    {
      img: "/partners/logo_tecnitasax300.png",
      company: "Grupo Tecnitasa",
      employees: [
        {
          name: "Sergio Espadero Colmenar, MRICS",
          email: "sespadero@tecnitasa.es",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "Head of Consulting & Valuation",
            es: "Director de Consultoría y Valoración"
          },
          department: [{
            en: "Company & Asset valuation",
            es: "Valoración de Empresas y Activos"
          }],
          "profile": {
            en: "https://www.tecnitasa.es/equipo/sergio-espadero-colmenar-mrics/",
            es: "https://www.tecnitasa.es/equipo/sergio-espadero-colmenar-mrics/"
          }
        }
      ]
    },
    {
      img: "/partners/logo_savillsx300.png",
      company: "Savills",
      employees: [
        {
          name: "Fernando Vigueras Muñoz",
          email: "fernando.vigueras@savills.es",
          city: {
            en: "Spain",
            es: "España"
          },
          rol: {
            en: "National Manager",
            es: "Director Nacional"
          },
          department: [{
            en: "Company & Asset valuation",
            es: "Valoración de Empresas y Activos"
          }],
          "profile": {
            en: "https://en.savills.es/people/fernando-vigueras.aspx",
            es: "https://www.savills.es/personas/fernando-vigueras.aspx"
          }
        }
      ]
    }
  ],
  "technologyProviders": [
    {
      img: "/partners/logo_alterx300.png",
      company: "Alter",
      "description": {
        en: "<p>Alter Software is the technology partner of several top-tier international banks and investment firms. They have more than 20 years of experience in the spanish IT financial sector helping their clients in several fields like: brokerage, settlement, custody, reporting, advisory and portfolio management.</p><p>ALTER Finance is their product for financial institutions and the one that is helping Portfolio Stock Exchange managing all the back office and regulatory compliance that we need as a Multilateral Trading Facility.</p><p>Their deep understanding of the needs of Capital Markets firms, the huge capacity of their technical team and efficient service for implementation makes them a perfect partner to help us become a successful stock exchange.</p>",
        es: "<p>Alter Software es el socio tecnológico de múltiples bancos internacionales y firmas de inversión de primer nivel. Con más de 20 años de experiencia en el sector financiero español han ayudado a sus clientes en áreas como: compra/venta de valores, liquidación, custodia, comunicaciones, asesoría financiera o gestión de carteras.</p><p>ALTER Finance es su producto para instituciones financieras que ayuda a Portfolio en la gestión de cumplimiento normativo y administración del Sistema Multilateral de Negociación.</p><p>Su profundo conocimiento de las necesidades de las empresas en Mercados de Capitales y la gran capacidad de su equipo técnico se unen para ser un socio perfecto para ayudar a llevar al éxito a Portfolio.</p>"
      }
    }
  ]
}

export default partners
